<template>
  <v-navigation-drawer
    v-if="!$vuetify.display.mobile"
    :key="globalSidebarOpenClosedState || currentGlobalSidebarType"
    v-model="isGlobalSidebarOpen"
    class="custom-force-sidebar-on-top"
    location="right"
    width="480"
    :persistent="persistent"
    temporary>
    <v-container class="position-fixed bg-white sidebar-header">
      <v-row class="d-flex flex-row">
        <v-col cols="10">
          <span v-if="title" class="font-weight-bold">{{ title }}</span>
        </v-col>

        <v-col cols="2" class="d-flex align-center justify-end">
          <v-icon
            id="close-global-sidebar"
            role="button"
            aria-label="close-global"
            @click="closeSidebar">
            mdi-close
          </v-icon>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mt-14">
      <v-row class="d-flex flex-column">
        <v-col cols="12" class="pt-0">
          <!-- All content coming from slot -->
          <slot />
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>

  <v-bottom-sheet
    v-if="$vuetify.display.mobile"
    v-model="isGlobalSidebarOpen"
    width="100%"
    max-height="90vh"
    height="90vh"
    persistent>
    <v-card class="rounded-t-lg" height="90vh">
      <v-container class="position-fixed bg-white sidebar-header">
        <v-row class="d-flex flex-row">
          <v-col cols="10">
            <span v-if="title" class="font-weight-bold">
              {{ title }}
            </span>
          </v-col>

          <v-col cols="2" class="d-flex align-center justify-end">
            <v-icon
              id="close-global-bottom-sheet"
              role="button"
              aria-label="close-global-bottom-sheet"
              @click="closeSidebar">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="mt-14">
        <v-row class="d-flex flex-column">
          <v-col cols="12" class="pt-0">
            <!-- All content coming from slot -->
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-bottom-sheet>
</template>

<script setup lang="ts">
const sidebarStore = useSidebarStore()
const { isGlobalSidebarOpen, globalSidebarOpenClosedState, currentGlobalSidebarType } =
  storeToRefs(sidebarStore)

defineProps<{
  title?: string
  persistent?: boolean
}>()

const closeSidebar = () => sidebarStore.closeSidebar()
</script>
